<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { registerAutoMLModuleContext, AUTO_ML_MODULE_NAMESPACE } from '@/modules/autoML'

export default defineComponent({
  name: 'ModelTrainingIndex',
  setup () {
    registerAutoMLModuleContext()
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(AUTO_ML_MODULE_NAMESPACE)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo'])
  }
})
</script>
